import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




$('.service-single-casestudy-slides').slick({
  arrows: true,
  dots: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      centerPadding: '45px',
      centerMode: true,
      slidesToShow: 1
    }
  }]
});

$('.recruit-principle-slides').slick({
  arrows: true,
  dots: true,
  infinite: true,
  autoplay: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});

$('.recruit-interview-slides').slick({
  arrows: true,
  dots: false,
  infinite: true,
  autoplay: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false,
});
